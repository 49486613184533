import Axios from '../Axios';

export const getVenta = async () => {
    try {
      
      const {data}  = await Axios.get(
        '/firmantes');
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const crearVenta = async (firmanteData) => {
    try {
        
        const {data}  = await Axios.post(
            '/ventas', firmanteData
        );
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateVenta = async (contactoData) => {
    try {

        const {data}  = await Axios.patch(
          `/firmantes/${contactoData._id}`, contactoData
        );

        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
  };

  export const deleteVenta = (Id) => Axios.delete(`/firmantes/${Id}`);