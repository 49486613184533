
export const fetchUser = () => {
   // console.log('LO QUE HAY AL PREGUNTAR ' + window.localStorage.getItem('profile'));
    if (window.localStorage.getItem('profile')){
        const userData = window.localStorage.getItem('profile') 
        ? JSON.parse(localStorage.getItem('profile')) : 
        null;
        return userData;
    }
    return null;
}

export const fetchAlmacen = () => {
     if (window.localStorage.getItem('almacen')){
         const almacenData = window.localStorage.getItem('almacen') 
         ? JSON.parse(localStorage.getItem('almacen')) : 
         null;
         return almacenData;
     }
     return null;
 }
 