import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { commitTransbank } from '../../services/Transbank';
import { useLocation, useNavigate } from 'react-router-dom';
import {generaToken, validaSerieRun, solicitudFirmaSimple, descargaDirectaFESPFX } from '../../services/Esign';
import generaPFX from '../../components/GeneraPfx';
import apiConfig from '../../services/ApiConfig';
import { useFirmanteMutations, useFirmaSimpleMutations, useVentaMutations, useTransbankMutations } from '../../hooks';
import {Button} from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';

const CheckoutResultPage = () => {
  const {currentColor } = useStateContext();
  const location = useLocation();
  const navigate = useNavigate(); // React Router v6 usa `useNavigate` en lugar de `useHistory`
  const [result, setResult] = useState(null);
  const { addFirmante } = useFirmanteMutations();
  const { addFirmaSimple } = useFirmaSimpleMutations();
  const { addVenta } = useVentaMutations();
  const { addPago } = useTransbankMutations();

  // RESPUESTA TRANSBANK
  const [amount, setAmount] = useState(0);
  const [card_number, setCard_number] = useState('');
  const [authorization_code, setAuthorization_code] = useState('');

  const [numeroSolicitud, setNumeroSolicitud] = useState('');
  const [certificadoBase64, setCertificadoBase64] = useState('');

  const [pinFirma, setPinFirma] = useState('');

  // E-SIGN DATOS FIRMA SIMPLE 

  // RESCATAR DEL LOCAL
  const [procesoOn, setProcesoOn] = useState(null);
  const [fileCount, setFileCount] = useState('');

  const [dateVenta, setDateVenta] = useState(getFirstDayOfMonth('Fin')); // Fecha inicial

  let isExecuted = false; // Flag para prevenir la doble ejecución

  useEffect(() => {
    const token_ws = new URLSearchParams(location.search).get('token_ws');
    
    if (token_ws && !isExecuted) {
      isExecuted = true;  // Marcar como ejecutado

  
      commitTransbank({token: token_ws})
      .then((response) => {
        // Manejar el éxito
        // console.log("ESTE ES EL RETORNO " + JSON.stringify(response) );
        setAmount(response.amount);
        setCard_number(response.card_detail.card_number);
        setAuthorization_code(response.authorization_code);
        setResult('success');
        handleGeneraFS(response.vci, response.status, response.buy_order,response.session_id, 
          response.card_detail.card_number,response.accounting_date,response.transaction_date,
          response.authorization_code,response.payment_type_code,response.response_code, response.amount);
      })
      .catch((error) => {
        // Manejar el error
        setResult('error');
      })
      .finally(() => {
        // Limpia la URL después de procesar el token
        navigate('/checkout-result', { replace: true });
      });
    }
  }, [location,navigate]);

  function getFirstDayOfMonth(Como) {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    if(Como === 'Inicio') return firstDay;
    if(Como === 'Fin') return today;
    if(Como === 'Nada') return null;
  };

  const BarraModal = ({ processCount, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg">
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <p className="text-sm text-blue-900 bg-opacity-75 p-2 rounded-lg mb-2">
                GENERANDO FIRMA { processCount }
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <Button
                color="white"
                bgColor={currentColor}
                text="Cancelar"
                size="sm"
                borderRadius="10px"
                width="full"
                handleClick={onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const closeSuccessPayment = async () => {
    navigate('/');
    }

  const handleCancelViewer = () => {
    setProcesoOn(null);
  };

  const handleGeneraFS = async (vci, status, buy_order,session_id, 
    card_number,accounting_date,transaction_date,
    authorization_code,payment_type_code,response_code, amount) => {
    setProcesoOn("GO");
    try {

        let misDatos = localStorage.getItem('sellData');

        if (misDatos) {
          misDatos = JSON.parse(misDatos);
          //console.log("MIS DATOS SON " + misDatos );
        } else {
          console.log('No hay datos en localStorage');
          handleCancelViewer();
          return false;
        }

        // CREAR FIRMANTE
        let firmanteData = {
            "rut": misDatos.rutCliente,
            "nombres": misDatos.nombreCliente,
            "apellidoPaterno": misDatos.apellidoPatCliente,
            "apellidoMaterno": misDatos.apellidoMatCliente,
            "email": misDatos.emailCliente,
            "telefonoMovil": misDatos.telefonoCliente,
            "telefonoFijo": misDatos.telefonoCliente,
            "direccion": misDatos.direccionCliente
        };

        let firmanteDB = await addFirmante.mutateAsync( firmanteData )

        if (!firmanteDB) {
            toast.error('Problemas al registrar los datos del firmante.');
            handleCancelViewer();
            return;
        }

        setFileCount(' ---- ');
      
        // CREAR FIRMANTE
        let transbankData = {
          "vci": vci,
          "buy_Order": buy_order,
          "session_Id": session_id,
          "card_number": card_number,
          "accounting_date": accounting_date,
          "transaction_date": transaction_date,
          "authorization_code": authorization_code,
          "payment_type_code": payment_type_code,
          "response_code": response_code,
          "amount": amount,
          "estado": status
        };

        let pagoDB = await addPago.mutateAsync( transbankData )

        if (!pagoDB) {
            toast.error('Problemas al registrar los datos del Pago.');
            handleCancelViewer();
            return;
        }
        setFileCount(' \\\\ ');
        
        let tmpVigencia = misDatos.detallePedido.map( item => {
            let vigencia;

            // Evaluar según el valor de item.id
            if (item.id === "66b29a26a8a99d47ee8179f9") {
              vigencia = "365";
            } else if (item.id === "66b2d338a8a99d47ee8179fa") {
              vigencia = "730";
            } else if (item.id === "66d9065fc05820de0115a044") {
              vigencia = "1095";
            }
            return vigencia;
        });

        // console.log("VIGENCIA ES " + tmpVigencia[0] );

        let detalleVenta = misDatos.detallePedido.map((item, index) => ({
            lin: index + 1,  // Línea, puedes usar el índice del array
            producto: item.id,  // Asignar el id como producto
            cantidad: item.quantity,  // Asignar la cantidad
            precioUnitario: item.price,  // Asignar el precio unitario
            precioTotal: item.price * item.quantity  // Calcular el precio total
          }));


        const esignSuccess = await triggerEsignAPI(misDatos.rutCliente,
          misDatos.numeroDocumentoCliente,misDatos.nombreCliente,misDatos.apellidoPatCliente,
          misDatos.apellidoMatCliente,misDatos.direccionCliente,misDatos.emailCliente,
          misDatos.telefonoCliente,tmpVigencia[0],misDatos.claveCliente, 
          firmanteDB._id,misDatos.codigoDescuento,pagoDB._id,misDatos.totalPedido,detalleVenta);
        if (!esignSuccess) {
            toast.error('Hubo un error al procesar la firma.');
            handleCancelViewer();
            return;
        }

        toast.success('Firma procesada correctamente.');

        // Cerrar el modal y CheckOutPayment
        finalizeCheckout();

    } catch (error) {
        // toast.error('Hubo un error inesperado.');
        setProcesoOn(null);
    }
};

const finalizeCheckout = () => {
  setProcesoOn(null);
};

const changeModal = () => {
  setFileCount(' ... '); 
  setFileCount('     '); 
  setFileCount(' ... ');
};
  const triggerEsignAPI = async (rutCliente,numeroDocumentoCliente,nombreCliente,apellidoPatCliente,apellidoMatCliente,direccionCliente,emailCliente,telefonoCliente,vigenciaFirma,claveCliente,firmanteId,codigoDescuento,codigoTransbank, totalPedido,detalleVenta) => {
    try{
        const dataToken = await generaToken();
        if (!dataToken && dataToken.token === null) {
            // console.log("TOKEN ES", dataToken.token);
       // } else {
            // console.error("Error al obtener el token o token no válido.");
            toast.error('Hubo un error inesperado. Problemas para conectar con entidad emisora. Reintente!!!');
            return false;
        }

        changeModal();

        let dataRun = JSON.stringify({
            "rut": rutCliente,
            "numeroDocumento": numeroDocumentoCliente,
            "nombres": nombreCliente,
            "apellidoPaterno": apellidoPatCliente,
            "apellidoMaterno": apellidoMatCliente,
            "email": emailCliente,
            "origen": apiConfig.usuarioEsign
        });

        const esValido = await validaSerieRun(dataRun,dataToken.token);
        if (!esValido && esValido.codigoValidacion === null) {
        //    setCodigoValidacion(dataToken.codigoValidacion);
            // console.log("Codigo validación es : ", dataToken.codigoValidacion);
       // } else {
            // console.error("Error al validar SERIE de RUT.");
            toast.error('La serie ingresada asociada al RUT no es valida. Reintente!!!');
            return false;
        }

      changeModal();   

        let dataFS = JSON.stringify({
            "rut": rutCliente,
            "nombres": nombreCliente,
            "apellidoPaterno": apellidoPatCliente,
            "apellidoMaterno": apellidoMatCliente,
            "nroSerieCedula": numeroDocumentoCliente,
            "email": emailCliente,
            "telefonoMovil": telefonoCliente,
            "telefonoFijo": telefonoCliente,
            "vigencia": vigenciaFirma,
            "direccion": direccionCliente,
            "clave": claveCliente,
            "tipoCertificado": "FES",
            "origen": apiConfig.usuarioEsign,
            "estado": "",
            "idValidacion": esValido.codigoValidacion
        });

        const dataFirma = await solicitudFirmaSimple(dataFS,dataToken.token);

        if(!dataFirma && dataFirma.pin === null) {
            toast.error('Problemas al generar Firma Simple - Solicitud. Reintente!!!');
            return false;                
        }

        changeModal();

        let dataDescarga = JSON.stringify({
            "numeroSolicitud": dataFirma.numeroSolicitud,
            "pin": dataFirma.pin,
            "clave": claveCliente,
            "origen": apiConfig.usuarioEsign
        });

        setNumeroSolicitud(dataFirma.numeroSolicitud); 
        setPinFirma(dataFirma.pin);

        const dataFirmaBase64 = await descargaDirectaFESPFX(dataDescarga,dataToken.token);
        if (!dataFirmaBase64){
            toast.error('Problemas al generar Firma Simple - Descarga. Reintente!!!');
            return false;
        }

      changeModal();

        setCertificadoBase64(dataFirmaBase64.certificado);
        
        await generaPFX(dataFirmaBase64.certificado, rutCliente + "-" + dataFirma.numeroSolicitud )

        // CREA regisro compra en dataFirmaSimple
        let datosFirmaVenta = JSON.stringify({
          "firmante" : firmanteId, // idFirmante,
          "numeroDocumento": numeroDocumentoCliente,
          "codigoValidacion": esValido.codigoValidacion,
          "vigencia": vigenciaFirma,
          "clave": claveCliente,
          "pin":  dataFirma.pin,
          "numeroSolicitud": dataFirma.numeroSolicitud,
          "firmaBase64": dataFirmaBase64.certificado,
      });

      let dataFirmaSimple = await addFirmaSimple.mutateAsync( datosFirmaVenta );

      changeModal();
        // CREAR VENTA 
        let datosVenta = JSON.stringify({
            "fechaVenta": dateVenta.toISOString(),
            "descuento":  null,
            "firmaSimple": dataFirmaSimple._id ,
            "transbank": codigoTransbank,
            "totalNeto": totalPedido - Math.trunc(totalPedido * 0.19),
            "totalDescuentos": 0,
            "totalImpuestos": Math.trunc(totalPedido * 0.19),
            "total": totalPedido,
            "tipoPago": "webpayplus",
            "estado": "PAGADO" ,
            "detalleProductos": detalleVenta
        });

        await addVenta.mutateAsync(datosVenta);

        changeModal();

        return true;

    } catch (error) {
      toast.error('Hubo un error inesperado.');
      return false;
    }
};


  const handleDescargar = async () => {
      
      let misDatos = localStorage.getItem('sellData');

      if (misDatos) {
        misDatos = JSON.parse(misDatos);
        //console.log("MIS DATOS SON " + misDatos );
      } else {
        console.log('No hay datos en localStorage');
        return false;
      }
      // Download base 64
      await generaPFX(certificadoBase64, misDatos.rutCliente + "-" + numeroSolicitud )
        
    };

  return (
    <div>
      {result === 'success' && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl mb-4">Confirmación Pago</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="flex flex-col ">
                                <label className="text-sm font-semibold mb-1">AUTORIZACIÓN</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 rounded"
                                    value = {authorization_code}
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-sm font-semibold mb-1">Tarjeta</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 rounded"
                                    value = {'XXXX XXXX XXXX ' + card_number}
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col ">
                                <label className="text-sm font-semibold mb-1">Monto</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 rounded"
                                    value = {amount}
                                    disabled
                                />
                            </div>


                            <div className="flex flex-col md:col-span-3">
                                <div className="text-lg text-center text-lime-600 mb-4">
                                    <p className="mb-2">
                                       Gracias por Preferirnos, la descarga de su firma es automática. Gracias.  
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <button
                                className="bg-gray-500 text-white py-2 px-4 rounded"
                                onClick={closeSuccessPayment}
                            >
                                Finalizar
                            </button>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded"
                                onClick={handleDescargar}
                            >
                                Descargar
                            </button>
                        </div>
                    </div>
                </div>
      ) }
      {procesoOn && <BarraModal processCount={fileCount} onCancel={handleCancelViewer} />}
      {result === 'error' && <div>Payment Failed</div>}
    </div>
  );
};

export default CheckoutResultPage;
