import React from 'react'

const Button = ({ icon, bgColor, color, bgHoverColor, size, text, borderRadius, width , handleClick, loMuestro }) => {
  return (
    <button
    type="button"
    style={{ backgroundColor: bgColor, color, borderRadius }}
    className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    onClick = { handleClick }
    disabled = {loMuestro}
    >
      {icon} {text}
    </button>

  )
}

export default Button