import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PedidosWeb  from './pages/PedidosWeb/PedidosWeb';
import CheckOutPaymentPage from './pages/PedidosWeb/CheckOutPaymentPage';
import CheckOutResultPage from './pages/PedidosWeb/CheckOutResultPage'; // A page to handle Transbank result

const App = () => {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<PedidosWeb />} />
        <Route path="/checkout" element={<CheckOutPaymentPage />} />
        <Route path="/checkout-result" element={<CheckOutResultPage />} />
      </Routes>
    </Router>
    {/* <PedidosWeb /> */}
    </div>
  );
};

export default App;
