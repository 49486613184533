import Axios from '../Axios';
import { fetchUser } from '../utils/fetchUser';

export const getCategorias = async () => {
  try {
      const {data}  = await Axios.get(
        '/categorias'
      );
    //  console.log("CATEGORIAS " + JSON.stringify(data));
      return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getSubCategorias = async () => {
    try {
        const {data}  = await Axios.get(
          '/subcategorias'
        );
        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
    };
