import Axios from '../Axios';

export const getFirmaSimple = async () => {
    try {
      
      const {data}  = await Axios.get(
        '/firmasimple');
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const crearFirmaSimple = async (firmanteData) => {
    try {
        
        const {data}  = await Axios.post(
            '/firmasimple', firmanteData
        );
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateFirmaSimple = async (contactoData) => {
    try {

        const {data}  = await Axios.patch(
          `/firmasimple/${contactoData._id}`, contactoData
        );

        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
  };

  export const deleteFirmaSimple = (Id) => Axios.delete(`/firmasimple/${Id}`);