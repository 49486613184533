import { useMutation, useQueryClient } from "react-query";

import { crearContacto} from '../../services/Contactos';

import { toast } from 'react-hot-toast';
const queryKey = "contacto";

export const useContactoMutations = () => {
  const queryClient = useQueryClient();
  const onError = (err = null) => {
    if (err.statusCode === 409) {
      return toast.error(`El valor ${err.duplicateValue} ya se encuentra registrado`);
    }
    toast.error('Error consulte con Soporte!');
  };

  const onSuccess = () => {
    //toast.success('Datos Guardados Exitosamente!');
    queryClient.invalidateQueries(queryKey);
  };
  const addContacto = useMutation((body) => crearContacto(body), {
    onSuccess,
    onError: (err) => onError(err),
  });

  
  return { addContacto };
};
export default useContactoMutations;