import Axios from '../Axios';
import { fetchUser } from '../utils/fetchUser';

export const getProductos = async () => {
  try {
      const {data}  = await Axios.get(
        `/productos`
      );
     // console.log(' ME TRAJE ' + JSON.stringify(data))
      return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getProductosTerm = async (searchTerm) => {
    try {

        const {data}  = await Axios.get(
          `/productos/term?searchTerm=${searchTerm}`
        );
      // console.log(' ME TRAJE ' + JSON.stringify(data))
        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
    };

  export const getSearchProductos = async () => {
    try {
        const userInfo = fetchUser();
        const {data}  = await Axios.get(
          `/productos/search?empresa=${userInfo.empresa}&sucursal=${userInfo.sucursal}`
        );
      // console.log(' ME TRAJE ' + JSON.stringify(data))
        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
    };

    export const getProductosDashboard = async () => {
      try {
          const userInfo = fetchUser();
          const {data}  = await Axios.get(
            `/productos/dashboard?empresa=${userInfo.empresa}`
          );
        //  console.log(' ME TRAJE ' + JSON.stringify(data));
          
          return  data;
        } catch (error) {
          return Promise.reject(error);
        }
      };

      export const getProductosDashboardTabla = async () => {
        try {
            const userInfo = fetchUser();
            const {data}  = await Axios.get(
              `/productos/dashboard/tabla?empresa=${userInfo.empresa}`
            );
            console.log(' ME TRAJE ' + JSON.stringify(data));
            
            return  data;
          } catch (error) {
            return Promise.reject(error);
          }
        };


  export const crearProducto = async (producto) => {
    try {
      const userInfo = fetchUser();
      producto.empresa = userInfo.empresa;
      producto.sucursal = userInfo.sucursal;
      producto.nombreProducto = producto.descripcionProducto;

      console.log(' VOY A ENVIAR ' + JSON.stringify(producto));
      
      const {data}  = await Axios.post(
        '/productos', producto
      );
      // console.log(' ME TRAJE ' + JSON.stringify(data))
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateProducto = async (producto) => {
    try {
        const userInfo = fetchUser();
        producto.empresa = userInfo.empresa;
        producto.sucursal = userInfo.sucursal;
        const {data}  = await Axios.patch(
          `/productos/${producto._id}`, producto
        );
        // console.log(' ME TRAJE ' + JSON.stringify(data))
        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
  };

  export const deleteProducto = (Id) => Axios.delete(`/productos/${Id}`);

  export const getPacks = async (codigoPack) => {
    try {
        const userInfo = fetchUser();
        const {data}  = await Axios.get(
          `/packs?empresa=${userInfo.empresa}&sucursal=${userInfo.sucursal}&codigoPack=${codigoPack}`
        );

        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
    };

    export const crearProductoPack = async (packs) => {
      try {
        console.log(' VOY A ENVIAR ' + JSON.stringify(packs));
        const {data}  = await Axios.post(
          '/packs', packs
        );
        // console.log(' ME TRAJE ' + JSON.stringify(data))
          return  data;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  
    export const updateProductoPack = async (packs) => {
      try {

        const updateData = { 
          empresa: packs.empresa,
          sucursal: packs.sucursal,
          codigoPack: packs.codigoPack,
          codigoProducto: packs.idCodigoProducto,
          cantidad: packs.cantidad
          };
          console.log(' Datos a ARREGLADOS Enviar a update  [' +  JSON.stringify(updateData) + ']' );


        console.log(' ANTES DE ENVIAR A UPDATE [' + packs._id + '] ' + JSON.stringify(packs));

          const {data}  = await Axios.patch(
            `/packs/${packs._id}`, updateData
          );

          return  data;
        } catch (error) {
          return Promise.reject(error);
        }
    };
  
    export const deleteProductoPack = (Id) => Axios.delete(`/productos/packs/${Id}`);

