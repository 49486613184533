const env = process.env.REACT_APP_ESIGN_ENV;
const usuarioEsign = 'Creating_360_idyfdd_qa_fes';
const claveEsign = '8487c3736f0b7f4c82a7edb8638b1a6aa8e3ecb1';
// const appUrl = 'http://localhost:3000';

 const appUrl = 'https://www.firmatodo.cl';

const apiConfig = {
  dev: {
    API_BASE: 'https://idfd-qa.e-sign.cl:8443/esign-api-mvn/rest-services',
  },
  prod: {
    API_BASE: 'https://server-production-a29c.up.railway.app',
  },
  testing: {
    API_BASE: 'https://server-production-a29c.up.railway.app',
  },
  env,
  usuarioEsign,
  claveEsign,
  appUrl,
};


export default apiConfig;
