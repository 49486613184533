import React, { useState } from 'react';
import { Button } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { formatCurrency } from '../../formatters';
import ProductModal from './ProductModal'; // Asegúrate de importar el nuevo componente
import { Tooltip } from '@reach/tooltip';
import '@reach/tooltip/styles.css';

const ProductList = ({ products, addToCart }) => {
  const { currentColor } = useStateContext();
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {products.map((product) => (
          <div key={product.id} className="p-4 border rounded-lg shadow-md bg-white dark:bg-gray-800">
            <div className="flex flex-col items-center">
              <h2 className="text-gray-700 text-lg font-semibold dark:text-gray-300">{product.name}</h2>
              <div className="flex items-center text-gray-500 text-sm font-normal dark:text-gray-400 mb-2">
                <p>Precio: {formatCurrency(product.price)}</p>
                <Tooltip label="Ver descripción del producto">
                  <button
                    className="text-blue-700 font-bold hover:underline ml-2"
                    onClick={() => openModal(product)}
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
              <Button
                color="white"
                bgColor={currentColor}
                text="Agregar"
                borderRadius="10px"
                width="auto"
                handleClick={() => addToCart(product)}
                className="mt-4"
              />
            </div>
          </div>
        ))}
      </div>
      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          isOpen={Boolean(selectedProduct)}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default ProductList;
