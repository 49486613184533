import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carrusel.css';

const Carrusel = ({ productos, agregarAlCarrito }) => {
  const ArrowPrev = ({ ...props }) => (
    <button {...props} className="slick-arrow slick-prev">
      Anterior
    </button>
  );

  const ArrowNext = ({ ...props }) => (
    <button {...props} className="slick-arrow slick-next">
      Siguiente
    </button>
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3, // Cambiar el número de slides visibles según tus necesidades
    slidesToScroll: 1,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const imagenPorDefecto = '/img/default.png';

  return (
    <div className="carrusel-container">
      <Slider {...settings}>
        {productos &&
          productos.map((producto, index) => (
            <div key={index} className="carrusel-item" onClick={() => agregarAlCarrito(producto.categoria)}>
              <div className="carrusel-contenido">
                <img
                  src={producto.imagenCategoria ? `/img/${producto.imagenCategoria}` : imagenPorDefecto}
                  width={200}
                  height={200}
                  alt={producto.nombre}
                  className="carrusel-imagen"
                />
                <h3 className="text-gray-500 text-sm font-extrabold dark:text-gray-600">{producto.categoria}</h3>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default Carrusel;
