import React, { useState} from 'react';

const DeliveryPaymentOptions = ({ formaPago, setFormaPago, isChecked, setIsChecked, codigoDescuento, setCodigoDescuento }) => {
    
    return (
        <div className="mt-6">
            <h2 className="text-xl font-semibold mb-4">Opciones de Pago</h2>
            <div className="flex flex-col mb-4">
                <label className="text-sm font-semibold mb-1">Selecciona una opción de pago</label>
                <div className="grid grid-cols-1 gap-4">
                    <div
                        className={`p-4 border rounded cursor-pointer ${formaPago === 'webpayplus' ? 'border-blue-600' : 'border-gray-300'}`}
                        onClick={() => setFormaPago('webpayplus')}
                    >
                        <img src="/img/1.WebpayPlus_FB_800px.png" alt="WebPayPlus" className="h-16 mx-auto mb-2" />
                    </div>
                    {/* Otras opciones de pago se pueden agregar aquí */}
                </div>
            </div>
            <div className="flex flex-col md:col-span-2 mb-4">
                    <label className="text-sm font-semibold mb-1">Código Descuento</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={codigoDescuento}
                        onChange={(e) => setCodigoDescuento(e.target.value)}
                    />
            </div>
            <div className="text-sm mb-4">
                <p className="mb-2">
                    Tus datos personales serán usados solo para procesar tu orden, mejorar tu experiencia de compra y otros propósitos definidos en nuestra política de privacidad.
                </p>
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    He leído y estoy de acuerdo con los términos y condiciones de la web
                </label>
            </div>
        </div>
    );
};

export default DeliveryPaymentOptions;
