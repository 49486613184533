import React, { useState, useEffect } from 'react';
import { Carrusel, Header, FooterPages } from '../../components';
import ProductList from './ProductList';
import Cart from './Cart';
import { useStateContext } from '../../contexts/ContextProvider';
import { useGetCategoriasTipo, useGetAllInventario } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const PedidosWeb = () => {
  const { currentColor, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [categoriaSelect, setCategoriaSelect] = useState('');
  const categoriaProducts = useGetCategoriasTipo();
  const productsData = useGetAllInventario();
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (categoriaProducts.data && productsData.data) {
        const categoriaInicial = categoriaProducts.data[0];
        setCategoriaSelect(categoriaInicial.categoria);
        const initialProducts = productsData.data.filter(item => categoriaInicial.categoria === item.categoriaNombre);
        setProducts(initialProducts.map(item => ({
          id: item._id,
          name: item.nombreProducto,
          price: item.precioVenta,
          quantity: 1
        })));
      }
    };
    fetchData();
  }, [categoriaProducts.data, productsData.data]);

  useEffect(() => {
    const fetchData = async () => {
      if (productsData.data) {
        const filteredProducts = productsData.data.filter(item => categoriaSelect === item.categoriaNombre);
        setProducts(filteredProducts.map(item => ({
          id: item._id,
          name: item.nombreProducto,
          price: item.precioVenta,
          quantity: 1
        })));
      }
    };
    fetchData();
  }, [categoriaSelect, productsData.data]);

  const addToCart = (product) => {
    const existingItem = cart.find((item) => item.id === product.id);

    if (existingItem) {
      existingItem.quantity++;
      setCart([...cart]);
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }

    setTotal(total + product.price);
  };

  const removeFromCart = (product) => {
    const updatedCart = cart.filter((item) => item.id !== product.id);
    setCart(updatedCart);
    setTotal(total - product.price * product.quantity);
  };

  const updateQuantity = (product, quantityChange) => {
    const updatedCart = [...cart];
    const updatedItem = updatedCart.find((item) => item.id === product.id);

    if (updatedItem) {
      updatedItem.quantity += quantityChange;
      if (updatedItem.quantity < 1) {
        updatedCart.splice(updatedCart.indexOf(updatedItem), 1);
      }

      setCart(updatedCart);
      setTotal(total + quantityChange * product.price);
    }
  };

  const checkout = () => {
    // Navigate to the checkout page, passing the cart and total as state
    navigate('/checkout', { state: { totalPedido: total, detallePedido:cart } });
  };

  return (
    <div>      
       <Header isVisible={!isClicked.checkoutpedido} /> {/* Agrega el header aquí con la visibilidad controlada */}

      <div className="container mx-auto mt-24">
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col">
            {categoriaProducts.data && (
              <Carrusel productos={categoriaProducts.data} agregarAlCarrito={setCategoriaSelect} />
            )}
          </div>
          <div className="flex justify-between items-center mt-2 m-1 border-t-1 border-color"></div>
          <div className="flex flex-col">
            <ProductList products={products} addToCart={addToCart} />
          </div>
          <div className="flex flex-col">
            <Cart
              cart={cart}
              removeFromCart={removeFromCart}
              updateQuantity={updateQuantity}
              total={total}
              checkout={checkout}
            />
          </div>
        </div>
      </div>
      <FooterPages />
    </div>
  );
};

export default PedidosWeb;
