import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ContextProvider } from "./contexts/ContextProvider";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient(
  {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          retry: 0,
        },
      },
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools/>*/}
      <ContextProvider>
        <Toaster  position="top-left" />
        <App />
      </ContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);


