import React from 'react';
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { FiDelete } from "react-icons/fi";
import { Button } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { formatCurrency } from '../../formatters';

const Cart = ({ cart, removeFromCart, updateQuantity, total, checkout }) => {
  const { currentColor, isClicked, setIsClicked } = useStateContext();
  return (
    <div className="p-4 border rounded-lg">
      <h2>Carrito de Compras</h2>
      <ul>
        {cart.map((item) => (
          <li key={item.id} className="flex justify-between items-center">
            <div className="grid grid-cols-2 gap-4" >
              <div className="flex flex-col">
              <span><h2 className="text-gray-500 text-sm font-semibold dark:text-gray-600">{item.name}</h2></span>
              </div>  
              <div className="flex flex-col">

              </div> 

            </div>
            
            <div className="grid grid-cols-4 gap-4">
              <div className="flex flex-col items-center">
                <button
                  type="button"
                  onClick={() => updateQuantity(item, -1)}
                  style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                  className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <CiCircleMinus />
                </button>
              </div>
              <div className="flex flex-col items-center">
                  <input
                      className="text-xs border-0 p-2 "
                      type="text"
                      placeholder=""
                      name="cantidad"
                      disabled
                      value= {item.quantity}
                    />
              </div>
              <div className="flex flex-col items-center">
                <button
                  type="button"
                  onClick={() => updateQuantity(item, 1)}
                  style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                  className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <CiCirclePlus />
                </button>
              </div>
              <div className="flex flex-col items-center">
                <button
                  type="button"
                  onClick={() => removeFromCart(item)}
                  style={{ color: 'rgb(153, 171, 180)', borderRadius: '65%' }}
                  className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiDelete />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <p>Total: {formatCurrency(total)}</p>
      <Button
          color="white"
          bgColor= {'#0275d8'}
          text="Comprar"
          borderRadius="10px"
          width="75%"
          handleClick={() => checkout(cart)}
        />
    </div>
  );
};

export default Cart;
