import { useMutation, useQueryClient } from "react-query";

import { crearFirmaSimple} from '../../services/FirmaSimple';

import { toast } from 'react-hot-toast';
const queryKey = "firmasimple";

export const useFirmaSimpleMutations = () => {
  const queryClient = useQueryClient();
  const onError = (err = null) => {
    if (err.statusCode === 409) {
      return toast.error(`El valor ${err.duplicateValue} ya se encuentra registrado`);
    }
    toast.error('Error consulte con Soporte!');
  };

  const onSuccess = () => {
    //toast.success('Datos Guardados Exitosamente!');
    queryClient.invalidateQueries(queryKey);
  };
  const addFirmaSimple = useMutation((body) => crearFirmaSimple(body), {
    onSuccess,
    onError: (err) => onError(err),
  });

  
  return { addFirmaSimple };
};
export default useFirmaSimpleMutations;