import React from 'react';
import { MdClose } from 'react-icons/md';
import { formatCurrency } from '../../formatters';

const ProductModal = ({ product, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 w-1/2 z-10 relative">
        <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onClose}>
          <MdClose size={24} />
        </button>
        <h2 className="text-xl font-semibold mb-4">{product.name}</h2>
        <img src={`/img/${product.image || 'default.png'}`} alt={product.name} className="mb-4" />
        <p className="text-gray-500 mb-4">{product.description}</p>
        <p className="text-gray-500">Precio: {formatCurrency(product.price)}</p>
        {/* Agrega aquí más características si es necesario */}
      </div>
    </div>
  );
};

export default ProductModal;
