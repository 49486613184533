import React from 'react';
import { formatCurrency } from '../../formatters';

const OrderSummary = ({ totalPedido, detallePedido }) => {
    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Resumen del Pedido</h2>
            <ul>
                {detallePedido.map((item, index) => (
                    <li key={index} className="flex justify-between mb-2">
                        <span className="text-xs font-normal">{item.name} x {item.quantity}</span>
                        <span className="text-xs font-normal text-left">{formatCurrency(item.price * item.quantity)}</span>
                    </li>
                ))}
            </ul>
            <div className="flex justify-between mt-4 border-t pt-4">
                <span className="font-semibold">Total:</span>
                <span className="font-semibold">{formatCurrency(totalPedido)}</span>
            </div>
        </div>
    );
};

export default OrderSummary;