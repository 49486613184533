import Axios from '../Axios';

export const createTransbank = async (solicitudData) => {
    try {
        const {data}  = await Axios.post(
            '/transbank/create', solicitudData
        );
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const commitTransbank = async (transaccionData) => {
    try {
        
        const {data}  = await Axios.post(
            '/transbank/commit', transaccionData
        );
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const pagoTransbank = async (transaccionData) => {
    try {
        
        const {data}  = await Axios.post(
            '/pago', transaccionData
        );
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

