import axios from 'axios';
import apiConfig from './ApiConfig';

const getBaseUrl = () => {

    return apiConfig.dev.API_BASE;
  };

  export const generaToken = async () => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: getBaseUrl() + '/tokens/generarToken',
            headers: { 
                'X-API-APP': apiConfig.usuarioEsign, 
                'X-API-KEY': apiConfig.claveEsign
            }
        };
        
        const response = await axios.request(config);
        return response.data;  // Retorna directamente el objeto JSON
    } catch (error) {
        console.error('Error al obtener el token:', error);
        return null;  // Maneja el error retornando null u otro valor
    }
};

export const validaSerieRun = async (data, token) => {
    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url:  getBaseUrl() + '/validacionChallenge/validacionCedula',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + token
        },
        data : data
      };
      
      console.log(' CONFIG ES ' + config );
      console.log(' POST ' + data );

      const response = await axios.request(config);
      return response.data;  // Retorna directamente el objeto JSON
  } catch (error) {
      console.error('Error al validar Serie de Rut', error);
      return null;  // Maneja el error retornando null u otro valor
  }

};

export const solicitudFirmaSimple = async (data, token) => {
    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: getBaseUrl() + '/solicitud/ingresoSolicitud',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + token
        },
        data : data
      };
      
      const response = await axios.request(config);
      return response.data;  // Retorna directamente el objeto JSON
  } catch (error) {
      console.error('Error al enviar Solicitud de Firma Simple', error);
      return null;  // Maneja el error retornando null u otro valor
  }   

};

export const descargaDirectaFESPFX = async (data, token) => {
    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: getBaseUrl() + '/certificado/descargaDirectaFESPFX',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization':  'Bearer ' + token
        },
        data : data
      };
      
      const response = await axios.request(config);
      return response.data;  // Retorna directamente el objeto JSON
  } catch (error) {
      console.error('Error al descargar Firma Simple', error);
      return null;  // Maneja el error retornando null u otro valor
  }   

};