import { useQuery } from "react-query";
//@hooks
import { fetchUser } from '../../utils/fetchUser';
//@services
import {getCategorias} from "../../services/parametros";

export const useGetCategoriasTipo = ()  => {
  const userInfo = fetchUser();
  return useQuery(["categoriastipo"],async ()  => await getCategorias() ,
   {
    refetchOnMount: true,
  }
  );
};

export default useGetCategoriasTipo;