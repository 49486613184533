import Axios from '../Axios';

  export const getCodigoDescuento = async (searchCode) => {
      try {
        const {data}  = await Axios.get(
          `/descuento?codigo=${searchCode}`);
  
          // Esto se manejaría si la API devuelve status en el cuerpo de la respuesta
          console.log("DATOS RESULTADO", data);
          return data;
      } catch (error) {
          // Verificar si el error es 404
          if (error.response && error.response.status === 404) {
              console.log('Código de descuento no encontrado');
              return null; // Retorna null si el código de descuento no existe
          }
  
          console.error('Error en la llamada de API', error);
          throw error; // Lanza cualquier otro error
      }
  };
  
  export const updateCodigoDescuento = async (codData) => {
    try {

        const {data}  = await Axios.patch(
          `/descuento/${codData}`, { "_id": codData }
        );

        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
  };