import React, { useState, useEffect } from 'react';

const UserDetailsForm = ({
    rutCliente, setRutCliente,
    nombreCliente, setNombreCliente,
    apellidoPatCliente, setApellidoPatCliente,
    apellidoMatCliente, setApellidoMatCliente,
    direccionCliente, setDireccionCliente,
    telefonoCliente, setTelefonoCliente,
    emailCliente, setEmailCliente,
    numeroDocumentoCliente,setNumeroDocumentoCliente,
    claveCliente, setClaveCliente,
    reClaveCliente, setReClaveCliente
  }) => {

    return (
        <form>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="flex flex-col ">
                    <label className="text-sm font-semibold mb-1">RUT</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={rutCliente}
                        onChange={(e) => setRutCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-3">
                    <label className="text-sm font-semibold mb-1">Nombres</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={nombreCliente}
                        onChange={(e) => setNombreCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-2">
                    <label className="text-sm font-semibold mb-1">Apellido Paterno</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={apellidoPatCliente}
                        onChange={(e) => setApellidoPatCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-2">
                    <label className="text-sm font-semibold mb-1">Apellido Materno</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={apellidoMatCliente}
                        onChange={(e) => setApellidoMatCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-4">
                    <label className="text-sm font-semibold mb-1">Dirección</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={direccionCliente}
                        onChange={(e) => setDireccionCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-2">
                    <label className="text-sm font-semibold mb-1">Teléfono</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={telefonoCliente}
                        onChange={(e) => setTelefonoCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-2">
                    <label className="text-sm font-semibold mb-1">Email</label>
                    <input
                        type="email"
                        className="border border-gray-300 p-2 rounded"
                        value={emailCliente}
                        onChange={(e) => setEmailCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col md:col-span-4">
                    <div className="text-sm mb-4">
                        <p className="mb-2">
                            El número de Documento es el que aparece en su Cédula de Identidad y correponde a un número de 9 dígitos. La clave númerica de 4 digitos que inngrese debe utilizarla para utilizar la firma.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col md:col-span-2">
                    <label className="text-sm font-semibold mb-1">Número Documento</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 rounded"
                        value={numeroDocumentoCliente}
                        onChange={(e) => setNumeroDocumentoCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-sm font-semibold mb-1">Clave</label>
                    <input
                        type="password"
                        className="border border-gray-300 p-2 rounded"
                        value={claveCliente}
                        onChange={(e) => setClaveCliente(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-sm font-semibold mb-1">Re Ingrese Clave</label>
                    <input
                        type="password"
                        className="border border-gray-300 p-2 rounded"
                        value={reClaveCliente}
                        onChange={(e) => setReClaveCliente(e.target.value)}
                    />
                </div>
            </div>
        </form>
    );
};

export default UserDetailsForm;
