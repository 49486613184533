import React, { useState, useEffect } from 'react';

const Header = ({ isVisible }) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setIsHeaderVisible(false);
      } else {
        // Scrolling up
        setIsHeaderVisible(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed top-0 w-full transition-transform duration-300 z-50 ${isVisible && isHeaderVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="bg-blue-500 text-white p-4 flex items-center">
        <img src="img/firmatodo.png" alt="FirmaTodo Logo" className="h-8 w-8 mr-2" />
        <h1>Bienvenido a FirmaTodo selecciona los productos que necesita y presiona comprar</h1>
      </div>
    </header>
  );
};

export default Header;
