import React, { useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useContactoMutations } from '../../hooks';
import { toast } from 'react-hot-toast';

import { useStateContext } from '../../contexts/ContextProvider';


const FormularioContacto = () => {
    const { isClicked, setIsClicked } = useStateContext();
    const { addContacto } = useContactoMutations();
    const [formData, setFormData] = useState({
      origen: 'FIRMATODO',
      nombre: '',
      email: '',
      mobile: '',
      mensaje: '',
      estado: 'INICIO',
    });
  
    const [errors, setErrors] = useState({
      origen: 'FIRMATODO',
      nombre: '',
      email: '',
      mobile: '',
      mensaje: '',
      estado: 'INICIO',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const validate = () => {
        let isValid = true;
        let errors = {};
    
        if (!formData.nombre) {
          isValid = false;
          errors.nombre = 'El nombre es requerido.';
        }
        if (!formData.email) {
          isValid = false;
          errors.email = 'El email es requerido.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          isValid = false;
          errors.email = 'El email no es válido.';
        }
        if (!formData.mobile) {
          isValid = false;
          errors.mobile = 'El teléfono es requerido.';
        } else if (!/^[0-9]{11}$/.test(formData.mobile)) {
          isValid = false;
          errors.mobile = 'El teléfono no es válido.';
        }
        if (!formData.mensaje) {
          isValid = false;
          errors.mensaje = 'El mensaje es requerido.';
        }
    
        setErrors(errors);
        return isValid;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
          try {
            await addContacto.mutateAsync( formData )
    
            setFormData({
              origen: 'CTEAM',
              nombre: '',
              email: '',
              mobile: '',
              mensaje: '',
              estado: 'INICIO',
            });
            
            toast.success('Gracias por contactarnos, a la brevedad nos pondremos en contacto.');
            setIsClicked( !isClicked.contacto );
            
          } catch (error) {
            console.error('Error al enviar el mensaje:', error);
            alert('Hubo un problema al enviar el mensaje');
          }
        }
      };

    return (
        <div className="bg-half-transparent w-full fixed nav-item top-0 right-0 ">
        <div className=" overflow-y-scroll float-right h-screen duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white w-full md:w-2/4 p-8">
            <div className="flex justify-between items-center">
                <p className="font-semibold text-lg"> </p>
                <button
                    type="button"
                    onClick={ () => { setIsClicked( !isClicked.contacto )} }
                    style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                    className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                <MdOutlineCancel />
                </button>
            </div>

        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto p-4 md:p-8">
                <h1 className="text-2xl font-semibold mb-6">Contáctenos</h1>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="md:col-span-3 bg-white p-6 rounded-lg shadow-lg">
                    <form className="text-left" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700">Nombre</label>
                            <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            />
                            {errors.nombre && <p className="text-red-500 text-sm">{errors.nombre}</p>}
                        </div>
                        <div className="mb-4 flex space-x-4">
                            <div className="w-1/2">
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>
                            <div className="w-1/2">
                            <label className="block text-gray-700">Teléfono</label>
                            <input
                                type="text"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                            />
                            {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Mensaje</label>
                            <textarea
                            name="mensaje"
                            value={formData.mensaje}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            ></textarea>
                            {errors.mensaje && <p className="text-red-500 text-sm">{errors.mensaje}</p>}
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
                        >
                            Enviar
                        </button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    );
};

export default FormularioContacto;