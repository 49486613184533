import { useMutation, useQueryClient } from "react-query";

import { crearFirmante} from '../../services/Firmantes';

import { toast } from 'react-hot-toast';
const queryKey = "contacto";

export const useFirmanteMutations = () => {
  const queryClient = useQueryClient();
  const onError = (err = null) => {
    if (err.statusCode === 409) {
      return toast.error(`El valor ${err.duplicateValue} ya se encuentra registrado`);
    }
    toast.error('Error consulte con Soporte!');
  };

  const onSuccess = () => {
    //toast.success('Datos Guardados Exitosamente!');
    queryClient.invalidateQueries(queryKey);
  };
  const addFirmante = useMutation((body) => crearFirmante(body), {
    onSuccess,
    onError: (err) => onError(err),
  });

  
  return { addFirmante };
};
export default useFirmanteMutations;