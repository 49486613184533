/* eslint-disable */
import axios from 'axios';
import apiConfig from './utils/api';
import  {fetchToken} from './utils/fetchToken';

const env = process.env.REACT_APP_NODE_ENV;

const getBaseUrl = () => {
  if (env === 'testing') {
    return apiConfig.testing.API_BASE;
  }
  if (env === 'production') {
    return apiConfig.prod.API_BASE;
  }
  return apiConfig.dev.API_BASE;
};

const Axios = axios.create({ baseURL: getBaseUrl()});

/*
const Axios = axios.create({
  baseURL: getBaseUrl(),
  timeout: 20 * 60 * 1000,
  mode: 'no-cors',
  maxBodyLength: 10000000000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('token'))}`,
  },
});
*/


Axios.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${JSON.parse(window.localStorage.getItem('token'))}`;
  }
  return req;
});

/*
// Interceptor de respuestas para manejar errores de autorización
Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    console.log( 'ENTRE AL INTERCEPTOR ');
    
    // Manejar errores de autorización
    if (error.response?.status === 401) {
      if (originalRequest.url === '/usuario/changetoken') {
        // Si el error proviene de la solicitud para cambiar el token, limpiar el almacenamiento local y redirigir al inicio
        window.localStorage.clear();
        window.location.replace('/');
      } else if (!originalRequest._retry && originalRequest.url !== '/usuario/ingresaruser') {
        // Si el error proviene de una solicitud protegida y no es un intento de reintentar,
        // intentar obtener un nuevo token y reenviar la solicitud original
        originalRequest._retry = true;
        try {
          // Aquí debes hacer la solicitud para obtener un nuevo token
          const response = await Axios.post('/usuario/changetoken', {
            refreshToken: window.localStorage.getItem('refreshToken'),
          });

          // Si la solicitud para obtener un nuevo token es exitosa, actualizar el token en el almacenamiento local
          window.localStorage.setItem('token', JSON.stringify(response.data.token));

          // Reintentar la solicitud original con el nuevo token
          originalRequest.headers.Authorization = `Bearer ${JSON.parse(window.localStorage.getItem('token'))}`;
          return Axios(originalRequest);
        } catch (err) {
          // Si la solicitud para obtener un nuevo token falla, limpiar el almacenamiento local y redirigir al inicio
          window.localStorage.clear();
          window.location.replace('/');
        }
      }
    }
    
    window.localStorage.clear();
    window.location.replace('/');
    return false;
    // Propagar el error si no es un error de autorización
    return Promise.reject(error);
  }
);
*/

/*
Axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response) {
      if (
        error.response?.status === 401 &&
        originalRequest.url === '/auth/changetoken'
      ) {
        window.localStorage.clear();
        window.location.replace('/');
      }
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url !== '/auth/login' &&
        window.localStorage.getItem('rememberSession')
      ) {
        originalRequest._retry = true;
        try {
          const res = await Axios.post('/auth/changetoken', {
            refreshToken: window.localStorage.getItem('refreshToken'),
          });
          if (res.status === 200) {
            window.localStorage.setItem('token', res.data.accessToken);
            window.localStorage.setItem('refreshToken', res.data.refreshToken);
            Axios.defaults.headers.common.Authorization = res.data.accessToken;
            return Axios(originalRequest);
          }
        } catch (err) {
          if (err.response.status === 400) {
            window.localStorage.clear();
            window.location.replace('/');
          }
        }
      }
    }
    return Promise.reject(error);
  }
);
*/

Axios.defaults.headers.common['Content-Type'] = 'application/json';

export default Axios;
