import { useQuery } from "react-query";
//@hooks

//@services
import {getProductos} from "../../services/Inventario";

export const useGetAllInventario = ()  => {
  return useQuery(["productos"],async ()  => await getProductos() ,
   {
    refetchOnMount: true,
  }
  );
};
export default useGetAllInventario;