import { saveAs } from 'file-saver';

// Función para generar el archivo PFX desde Base64
export const generaPFX = async (base64Certificado, nombreArchivo) => {
  try {
    // Decodificar el Base64 a bytes
    const pfxBytes = await Uint8Array.from(atob(base64Certificado), c => c.charCodeAt(0));

    // Crear un blob con los bytes decodificados
    const blob = new Blob([pfxBytes], { type: 'application/x-pkcs12' });

    // Guardar el archivo usando el nombre deseado
    saveAs(blob, nombreArchivo + '.pfx');
  } catch (error) {
    console.error("Error al generar el archivo PFX:", error);
  }
};

export default generaPFX;